@import '@/styles/breakpoints.module';

.buttonsContainer {
  display: flex;
  position: relative;
  gap: 4px;
  & > * {
    width: 100%;
  }
}

.spread {
  position: absolute;
  z-index: 1;
  inset-inline-start: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  border-radius: var(--palette-border-radius-xs);
  background-color: var(--palette-white);
  padding: 4px 6px;
  width: auto;
  color: var(--palette-gray-900);
  max-width: 74px;
  overflow-wrap: anywhere;
  text-align: center;
  box-shadow: 0px 10px 15px 0px rgba(145, 149, 165, 0.2);
}

[dir='rtl'] {
  .spread {
    transform: translate(50%, -50%);
  }
}

.buySellContainer {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.progressWithLabels {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.progressLabels {
  display: flex;
  justify-content: space-between;
  span {
    font-weight: var(--palette-font-weight-bold);
  }
  & > :first-child > span {
    color: var(--palette-primary-700);
  }
  & :last-child > span {
    color: var(--palette-red-600);
  }
}

.progress {
  display: flex;
  gap: 4px;
  & > * {
    height: 8px;
    border-radius: var(--palette-border-radius-xxs);
  }

  & > :first-child {
    background-color: var(--palette-primary-700);
  }
  & :last-child {
    width: 100%;
    background-color: var(--palette-red-600);
  }
}

.tradingHours {
  display: flex;
  flex-direction: column;
  gap: 8px;
}
