@import '@/styles/breakpoints.module';

.layout {
  --row-gap: 16px;
  --col-gap: 8px;
  --sidebar-max-width: 360px;

  display: flex;
  flex-direction: column-reverse;
  justify-content: space-between;

  @include desktop {
    flex-direction: row;
    --row-gap: 30px;
  }

  .layoutSidebar {
    display: flex;
    flex-direction: column;
    flex: 1 0 auto;

    @include desktop {
      max-width: var(--sidebar-max-width);
    }

    & > *:not(:last-child) {
      margin-bottom: var(--row-gap);
      @include desktop {
        margin-bottom: var(--row-gap);
      }
    }
  }

  & > :not(.layoutSidebar) {
    flex: 1 1 auto;
    width: 100%;
    margin-bottom: var(--row-gap);

    @include desktop {
      margin-bottom: 0;
      max-width: 750px;
      margin-inline-start: var(--col-gap);
    }
  }
}
