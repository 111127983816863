@import '@/styles/breakpoints.module';

.row {
  display: flex;
  justify-content: space-between;
}

.field {
  & > :first-child {
    color: var(--palette-gray-500);
    font-weight: var(--palette-font-weight-medium);
    margin-bottom: 4px;
  }

  & > :last-child {
    font-weight: var(--palette-font-weight-semibold);
  }

  &:last-child {
    text-align: end;
  }
}
