@import '@/styles/breakpoints.module';

.sep {
  background: none;
}

@mixin vertical {
  min-height: 100%;
  height: auto;
  width: 100%;
  max-width: 1px !important;
  margin: 0 var(--sep-spacing-mobile);
  @include tablet {
    margin: 0 var(--sep-spacing-tablet);
  }
  @include desktop {
    margin: 0 var(--sep-spacing-desktop);
  }
}

@mixin horizontal {
  width: 100%;
  height: 1px;
  margin: var(--sep-spacing-mobile) 0;
  @include tablet {
    margin: var(--sep-spacing-tablet) 0;
  }
  @include desktop {
    margin: var(--sep-spacing-desktop) 0;
  }
}

.mobileVertical {
  @include vertical;
}

.mobileHorizontal {
  @include horizontal;
}

.tabletVertical {
  @include tablet {
    @include vertical;
  }
}

.tabletHorizontal {
  @include tablet {
    @include horizontal;
  }
}

.desktopVertical {
  @include desktop {
    @include vertical;
  }
}

.desktopHorizontal {
  @include desktop {
    @include horizontal;
  }
}

.mobileVisible {
  background-color: var(--palette-gray-100);
  .dark {
    background-color: var(--palette-gray-150);
  }
}

.tabletVisible {
  @include tablet {
    background-color: var(--palette-gray-100);
    &.dark {
      background-color: var(--palette-gray-150);
    }
  }
}

.desktopVisible {
  @include desktop {
    background-color: var(--palette-gray-100);
    &.dark {
      background-color: var(--palette-gray-150);
    }
  }
}
