/*
variables:

--with-trend-color-primary
--with-trend-color-secondary

exposed to child components to be available down the tree
 */

.negative.negative {
  --with-trend-color-primary: var(--color-trend-negative);
  --with-trend-color-secondary: var(--palette-red-50);
  color: var(--with-trend-color-primary);
}

.positive.positive {
  --with-trend-color-primary: var(--color-trend-positive);
  --with-trend-color-secondary: var(--palette-green-50);
  color: var(--with-trend-color-primary);
}
