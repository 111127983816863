@import '@/styles/breakpoints.module';

.hero {
  padding-top: 108px;
  @include tablet {
    padding-top: 96px;
  }
  @include desktop {
    padding-top: 172px;
  }
}

.heroInner {
  display: flex;
  flex-direction: column;
  gap: 24px;
  @include tablet {
    gap: 0;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }
}

.heroCoinSummary {
  display: flex;
  align-items: center;
  gap: 12px;
  .workingHours {
    align-items: center;
    margin-top: 12px;
    display: flex;
    flex-wrap: wrap;
    gap: 8px;
    @include tablet {
      gap: 12px;
    }

    .statusLabel {
      color: inherit;
      padding: 2px 12px;
      border: 1px solid currentColor;
      font-weight: var(--palette-font-weight-medium);
      border-radius: 4px;
    }
  }

  .icon {
    width: 48px;
    height: auto;
  }

  .assetName {
    font-weight: var(--palette-font-weight-bold);
  }
}

.heroCoinMarketData {
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding-inline-start: 60px;

  @include tablet {
    padding-inline-start: 0;
    gap: 4px;
  }

  .price {
    font-weight: var(--palette-font-weight-bold);
  }

  .change {
    font-weight: var(--palette-font-weight-medium);
    display: flex;
    gap: 6px;
    align-items: center;
    & > * {
      display: flex;
      gap: 6px;
    }
  }
}

.notTradedBanner {
  margin-top: 40px;
  padding: 1px;
  border-radius: 8px;
  background: linear-gradient(45deg, rgba(55, 96, 255, 1), rgba(150, 171, 255, 0.44));
  .notTradedBannerInner {
    gap: 12px;
    display: grid;
    grid-template-columns: auto 1fr;

    border-radius: 8px;
    padding: 16px;
    background-color: var(--palette-gray-700);

    @include tablet {
      padding: 24px;
      grid-template-columns: auto 1fr auto;
      grid-template-rows: auto auto;
    }

    .notTradedBannerTitle {
      align-self: center;
    }

    .notTradedBannerDescription {
      grid-column: span 2;
      @include tablet {
        grid-column: 1 / span 2;
        grid-row: 2;
      }
    }

    button {
      grid-column: span 2;
      @include tablet {
        grid-column: 3;
        grid-row: span 2;
        align-self: center;
      }
    }
  }
}

.buySellButtons {
  margin-top: 24px;

  @include tablet {
    margin-top: 40px;
    max-width: 50%;
    width: 100%;
  }

  @include desktop {
    display: none;
  }
}
